import React from 'react';
import Layout from '../components/Layout';
import Section from '../components/Section';
import { Container, Row, Column } from '../components/Grid';
import { Link } from 'gatsby';
import Image from '../components/Image';
import OutboundLink from '../components/OutboundLink';
import StudentQuote from '../components/StudentQuote';

const About = () => (
  <Layout title="About the 3M Young Scientist Lab" className="about">
    <div className="hero-container">
      <div className="hero-container__gradient bg-gradient-yellow"></div>
      <div className="hero-container__titleImage">
        {/* Breadcrumbs */}
        <ul className="breadcrumbs">
          <li className="breadcrumb active">
            About the 3M Young Scientist Lab
          </li>
        </ul>
        <h1 className="page-title">About the 3M Young Scientist Lab</h1>
        <img
          src="../images/6_about/hero-images/hero-about.png"
          className="hero-image"
          alt="About the 3M Young Scientist Lab"
        />
      </div>
    </div>
    {/* About Intro */}
    <Section>
      <Container>
        <Row>
          <Column size={8} offset={2} className="global-intro mt-1">
            <h2>Scientific Innovation Belongs to Everyone</h2>
            <h4>
              The 3M Young Scientist Lab empowers middle school students,
              educators, and family members to embrace scientific discovery in
              any environment. Discovery Education and 3M have partnered since
              2008 to spark student-centered STEM problem solving in and out of
              the classroom with a variety of standards-aligned resources, along
              with the award-winning annual 3M Young Scientist Challenge.
            </h4>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Bring the Science Lab to You */}
    <Section className="pt-0">
      <Container>
        <Row>
          <Column size={12} className="mt-1">
            <h2>Bring the Science Lab to You</h2>
          </Column>
        </Row>
        <Row>
          <Column size={4}>
            <div className="box-shadow hover">
              <Image filename="image-promo-1-game-changer.jpg" />
              <div className="p-1.5">
                <h4 className="mb-0 text-16 text-uppercase">Subject Matter</h4>
                <h3 className="mt-0 mb-0">Game-Changing Student Challenge</h3>
                <h4 className="mt-0 mb-0.5 text-20">
                  Connect students to a 3M Mentor!
                </h4>
                <p>
                  Ready to turn a simple idea into a one-of-a-kind learning
                  experience in the 3M Young Scientist Challenge? This
                  award-winning science competition provides the perfect
                  framework to encourage hands-on learning and bring real-world
                  scientific thinking to life.
                </p>
                <Link
                  to="/annual-challenge/about-the-challenge"
                  className="text-20 text-bold"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="box-shadow hover">
              <Image filename="image-promo-2-instructional-resources.jpg" />
              <div className="p-1.5">
                <h4 className="mb-0 text-16 text-uppercase">Subject Matter</h4>
                <h3 className="mt-0 mb-0">Instructional Resources</h3>
                <h4 className="mt-0 mb-0.5 text-20">
                  Encourage students to think like a scientist
                </h4>
                <p className="mb-2">
                  Access standards-aligned instructional resources that
                  cultivate science knowhow and creative problem-solving skills
                  for middle school students. Customize lessons to students’
                  interests with a wide variety of engaging activities,
                  interactives, lesson plans and more.
                </p>
                <Link
                  to="/science-starters/lesson-plans"
                  className="text-20 text-bold"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
          <Column size={4}>
            <div className="box-shadow hover">
              <Image filename="image-promo-3-DIY.jpg" />
              <div className="p-1.5">
                <h4 className="mb-0 text-16 text-uppercase">Subject Matter</h4>
                <h3 className="mt-0 mb-0">DIY Science Opportunities</h3>
                <h4 className="mt-0 mb-0.5 text-20">
                  Find easy ways to inspire student scientists.
                </h4>
                <p className="mb-2">
                  Explore real-world innovation with nothing more than simple
                  household items. Take advantage of easy opportunities to
                  engage students in solution seeking with the help of at-home
                  science experiments, family activities, and more.
                </p>
                <Link
                  to="/science-starters/science-at-home"
                  className="text-20 text-bold"
                >
                  Learn More {'>'}
                </Link>
              </div>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* About the Partners */}
    <Section>
      <Container>
        <Row className="mb-4">
          <Column size={12}>
            <h2 className="border-bottom pb-1">About the Partners</h2>
          </Column>
        </Row>
        <Row className="mb-5">
          <Column size={1}>
            <Image filename="3M-footer-logo.png" />
          </Column>
          <Column size={3}></Column>
          <Column size={7}>
            <h3>About 3M</h3>
            <p>
              At 3M, we apply science in collaborative ways to improve lives
              daily as our employees connect with customers all around the
              world. Learn more about 3M's creative solutions to global
              challenges at{' '}
              <OutboundLink to="https://www.3m.com" className="text-bold">
                www.3M.com
              </OutboundLink>{' '}
              or on Twitter{' '}
              <OutboundLink to="https://twitter.com/3M" className="text-bold">
                @3M
              </OutboundLink>{' '}
              or{' '}
              <OutboundLink
                to="https://twitter.com/@3MNews"
                className="text-bold"
              >
                @3MNews
              </OutboundLink>
              .
            </p>
          </Column>
        </Row>
        <Row className="mt-5">
          <Column size={3}>
            <Image filename="discovery-education-logo.png" />
          </Column>
          <Column size={1}></Column>
          <Column size={7}>
            <h3>About Discovery Education</h3>
            <p>
              Discovery Education is the worldwide edtech leader whose
              state-of-the-art digital platform supports learning wherever it
              takes place. Through its award-winning multimedia content,
              instructional supports, and innovative classroom tools, Discovery
              Education helps educators deliver equitable learning experiences
              engaging all students, and supporting higher academic achievement
              on a global scale. Discovery Education serves approximately 4.5
              million educators and 45 million students worldwide, and its
              resources are accessed in over 100 countries and territories.
              Inspired by the global media company Warner Bros. Discovery, Inc.
              Discovery Education partners with districts, states, and trusted
              organizations to empower teachers with leading edtech solutions
              that support the success of all learners. Explore the future of
              education at{' '}
              <OutboundLink
                to="https://www.discoveryeducation.com."
                className="text-bold"
              >
                {' '}
                www.discoveryeducation.com.
              </OutboundLink>
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Awards */}
    <Section>
      <Container>
        <Row>
          <Column size={12}>
            <h2 className="border-bottom pb-1">Awards</h2>
          </Column>
        </Row>
        <Row className="mb-5">
          <Column size={2}>
            <Image filename="educational-software-award.png" />
          </Column>
          <Column size={2} offset={2}>
            <Image filename="teacher-choice-award.png" />
          </Column>
          <Column size={4} offset={2}>
            <h3>The Shorty Awards</h3>
            <p>
              The 3M Young Scientist Challenge was awarded a Silver Distinction
              in the 2019 Shorty Awards in the category of STEM. Founded in
              2008, the Shorty Awards honors the work of brands, agencies,
              influencers, creators and other social media professionals
              producing outstanding work across digital and social platforms.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    {/* Student Stories Quote */}
    <Section className="student-stories">
      <StudentQuote
        image="image-snigtha-mohanraj-quote.png"
        name="Snigtha Mohanraj"
        year="Finalist 2021"
        body="This challenge was a fantastic opportunity to integrate myself more into the 
        world of brain computer interfaces and how they can be applied in early diagnosis 
        and treatment."
        path="/annual-challenge/finalists-mentors-judges/finalists/snigtha-mohanraj"
        classNames="bg-gradient-yellow cta__link no-arrow"
        colorbar="yellow"
        calloutText="Learn what sparked Snigtha’s journey"
      ></StudentQuote>
    </Section>
  </Layout>
);

export default About;
