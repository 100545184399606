import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import { Link } from 'gatsby';
import { Container, Row, Column } from '../Grid';
import Image from '../Image';
import './styles.scss';

const StudentQuote = ({
  image,
  name,
  year,
  body,
  path,
  classNames,
  colorbar,
  calloutText,
}) => (
  <Container>
    <Row>
      <Column size={5} offset={1}>
        <Image filename={image} className="br-tl br-br" alt={name} />
        <h3 className="text-center mt-1 mb-0">{name}</h3>
        <h4 className="text-center mid-gray mt-0 mb-0">{year}</h4>
      </Column>
      <Column size={5}>
        <div className="speech-bubble">
          <div className={colorbar}></div>
          <h3>“{body}”</h3>
        </div>
      </Column>
    </Row>
    <Row className="mb-2">
      <Column size={8} offset={4} className="mt-2 pb-6">
        <Link to={path} className={classNames}>
          {calloutText} <Icon name="arrowright" />
        </Link>
      </Column>
    </Row>
  </Container>
);

StudentQuote.propTypes = {
  image: PropTypes.string,
  name: PropTypes.string.isRequired,
  year: PropTypes.string.isRequired,
  body: PropTypes.node,
  path: PropTypes.node,
  classNames: PropTypes.string,
  colorbar: PropTypes.string,
  calloutText: PropTypes.string,
};

export default StudentQuote;
